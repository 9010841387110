.modal {
  background: rgba(44, 44, 44, 0.5);
}

.modal div.modal-header {
  display: block;
  text-align: center;
  color: none;
  border: none;
}
.modal .modal-title {
  color: #da1a43;
  font-size: 22px;
  font-weight: bold;
}

.modal-body h6 {
  color: #da1a43;
  font-size: 18px;
  font-weight: bold;
}

.modal-body p {
  margin-bottom: 20px;
}

.modal-body li {
  margin-bottom: 10px;
}

.modal-body nl {
  margin-bottom: 10px;
}

.modal-dialog {
}
.modal-title,
.modal-close span {
  color: #da1a43 !important;
}
.modal-content {
  border-radius: 0;
  background-color: #efefef !important;
  color: #0a1c3c;
  border-radius: 40px !important;
}

.paymentModal .modal-content {
  height: 290px;
  border-radius: 0;
  background-color: #da1a43 !important;
  color: #000000;
  border-radius: 40px !important;
}

.paymentModal .modal-content {
  height: 290px;
  border-radius: 0;
  background-color: #ffffff !important;
  color: #000000;
  border-radius: 40px !important;
}

.paymentModal .modal-content .modal-header {
  font-size: 26px;
  font-weight: 500;
  color: #ffffff !important;
  background-color: #007bff;
  border-top-left-radius: 39px;
  border-top-right-radius: 39px;
}

.modal-body {
  text-justify: inter-word;
  text-align: justify;
  word-wrap: break-word;
  padding-top: 15px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  overflow-y: auto;
  font-size: 16px;
  line-height: 20px;
}
.modal-body ol {
  padding-left: 15px;
}

.modal-close {
  position: relative;
  text-align: right;
  display: grid;
}
.modal-close span {
  font-size: 40px !important;
  line-height: 0;
  margin: 15px;
  cursor: pointer;
}

.modal ::-webkit-scrollbar {
  width: 5px;
  height: 18px;
}

.modal .scroll-element {
  scroll-margin: 50px 0 0 50px;
}
/* Track */
.modal ::-webkit-scrollbar-track {
  background: none;
}
.modal ::-webkit-scrollbar-corner {
  background: yellow;
}

.paymentModal ::-webkit-scrollbar-track {
  background: none;
}
/* Handle */
.modal ::-webkit-scrollbar-thumb {
  background: #da1a43;
  border-radius: 10px;
  height: 6px;
}

/* Handle on hover */
.modal ::-webkit-scrollbar-thumb:hover {
  background: #da1a43;
}
.home-menu-list .border1 {
  position: absolute;
  display: block;
  width: 100%;
  border: 2px solid #da1a43;
  box-sizing: border-box;
  background: none;
  margin: auto;
  transform: matrix(1, 0.04, -0.02, 1, 0, 0);
  min-height: 100%;
}
.home-menu-list .border2 {
  position: absolute;
  display: block;
  width: 100%;
  border: 2px solid #da1a43;
  box-sizing: border-box;
  background: none;
  margin: auto;
  transform: matrix(1, -0.05, 0.02, 1, 0, 0);
  min-height: 100%;
}
.selectModal .modal-body {
  min-height: 80%;
}

.modalModal .modal-body {
  overflow-x: hidden;
  padding: 1px !important;
}

.modalModal .modal-body .home-menu-list {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 40px;
}

.modalModal ::-webkit-scrollbar {
  display: none;
}

.navButton .left {
  border-radius: 30px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 45%;
  left: 0;
  background: rgba(10, 28, 60, 0.5);
  z-index: 1;
  font-size: 20px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}
.navButton .right {
  border-radius: 30px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 45%;
  right: 0;
  background: rgba(10, 28, 60, 0.5);
  z-index: 1;
  font-size: 20px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}
.menu-list {
  min-height: 400px;
  color: #da1a43;
  font-weight: normal;
}
.menu-name {
  font-size: 18px;
  color: #da1a43;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}
.menu-set-name {
  font-size: 16px;
  color: #da1a43;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .modal-body {
    text-justify: inter-word;
    text-align: justify;
    word-wrap: break-word;
    padding-top: 15px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    overflow-y: auto;
    font-size: 16px;
    line-height: 20px;
  }

  .modal-dialog.food-menu-modal {
    margin: 1.75rem auto;
    padding: 0;
  }
  .modalModal .modal-body .home-menu-list {
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 60px;
  }
  .modal-dialog.food-menu-modal .modal-content {
  }

  .navButton .left {
    border-radius: 30px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 45%;
    left: 10px;
    background: rgba(10, 28, 60, 0.5);
    z-index: 1;
    font-size: 30px;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .navButton .right {
    border-radius: 30px;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 45%;
    right: 10px;
    background: rgba(10, 28, 60, 0.5);
    z-index: 1;
    font-size: 30px;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  .menu-name {
    font-size: 24px;
    color: #da1a43;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
  }
  .menu-set-name {
    font-size: 20px;
    color: #da1a43;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
  }
}

#LockApp .modal-content {
  padding: 30px;
}

#LockApp .modal-content a {
  display: inline;
}
