html,
body {
  font-size: 14px;
}
button {
  outline: none;
}
a {
  color: #fff;
  transition: all 100ms ease-in;
}

a:hover {
  color: #cd026d;
  text-decoration: none;
}

header {
  background-color: #000;
  color: #5e5e5e;
}

.btn-outline {
  display: block;
  width: 200px;
  margin: 10px auto;
  color: #909090;
  padding: 5px;
  border: 1px solid #cd026d;
  border-radius: 20px;
  background-color: #ffffff;
}

.btn-outline:hover {
  color: #fff;
  background-color: #cd026d;
}

.btn-check {
  border: 1px solid #cd026d !important;
  border-radius: 20px !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}
.desktop-side-bg {
  background-color: #777476;
  background-size: cover;
}

#page-limousineService .desktop-side-bg {
  background-image: url('../imgs/limousine/bg.jpg');
}

.bg-grey {
  background-color: #737474;
  color: #fff;
  border-right: 2px solid #fff;
  background-image: linear-gradient(-90deg, #464b4e, #7a7a7a);
}

.red-gradient {
  height: 3px;
}

.bg-red,
.red-gradient {
  background-color: #4a0102;
  color: #fff;
  background-image: radial-gradient(circle, #da1a43 5%, #460000 60%);
}

.text-head {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
}
.text-head span {
  font-weight: 400;
  color: #da1a43;
}

.nav-main a {
  font-family: Roboto;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 300;
  color: #ababab;
  display: inline-block;
  position: relative;
}

.nav-main a span {
  font-weight: 400;
  color: #da1a43;
}

.nav-main a.active,
.nav-main a:hover {
  color: #fff;
}

.nav-main a.active span,
.nav-main a:hover span {
  color: #da1a43;
}

.nav-main a:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: #da1a43;
  bottom: 10px;
  left: 0;
  transition: all 100ms ease-in;
}

.nav-main a.active:after,
.nav-main a:hover:after {
  width: 100%;
}

#mobile-menu {
  width: 100%;
}

#mobile-menu-open {
  font-size: 1.5em;
  cursor: pointer;
  float: right;
}

#mobile-menu-content {
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #ffffff;
}
#mobile-menu-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#mobile-menu-close {
  font-size: 1.5em;
  cursor: pointer;
}

.btn-grey-border,
.btn-red-gradient {
  cursor: pointer;
  transition: all 100ms ease-in;
  border-radius: 20px;
  padding: 5px;
  width: 135px;
}

.btn-grey-border {
  color: #666666;
  border: 1px solid #666666;
  background-color: #ffffff;
}
.btn-grey-border:hover {
  color: #ffffff;
  background-color: #666666;
}

.btn-red-gradient {
  background-color: #4a0102;
  color: #fff;
  background-image: radial-gradient(circle, #da1a43 5%, #460000 60%);
  border: 0;
  position: relative;
}
.btn-red-gradient:hover {
  background-color: #4a0102;
  background-image: radial-gradient(circle, #da1a43 30%, #460000 100%);
}
.btn-red-gradient i {
  position: absolute;
  top: 9px;
  right: 8px;
}

textarea {
  /* box-sizing: padding-box; */

  resize: none;
}

div.react-datepicker-wrapper,
div.react-datepicker__input-container {
  width: 100%;
}

.form-control {
  border-radius: 20px !important;
  padding: 5px 20px !important;
  border: 1px solid #da1a43 !important;
  color: #000000 !important;
}
input #code-box .form-control {
  border-radius: '0px 20px 20px 0px' !important ;
  padding: 5px 20px !important;
  border: 1px solid #da1a43 !important;
  color: #000000 !important;
}
.input-group-addon {
  padding: 0.429rem 1rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.571429;
  color: white;
  text-align: center;
  background-color: #da1a43;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
label.error {
  color: red;
}
input.error {
  border: 1px solid red;
}
select.error {
  border: 1px solid red;
}
textarea.error {
  border: 1px solid red;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999;
  font-style: italic;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
  font-style: italic;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #999;
  font-style: italic;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
  font-style: italic;
}

#consent_form div.modal-header {
  display: block;
  text-align: center;
  color: #da1a43;
  border: none;
}
#consent_form button {
  border: 0;
  border-radius: 0;
  color: white;
  background-color: #da1a43;
  width: 90%;
  padding: 10px 15px;
}
.modal-dialog {
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: 0;
  margin: 0 auto;
}
.modal-dialog .modal-header {
  border-radius: 0;
}
.modal-dialog .modal-footer {
  border-radius: 0;
  border: none;
}
.modal-body {
  max-height: calc(100vh - 210px);
  text-justify: inter-word;
  word-wrap: break-word;
  padding-top: 0;
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: auto;
}
.modal-body ol {
  padding-left: 15px;
}
